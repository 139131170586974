import React from 'react'
import { Helmet } from 'react-helmet'
import Layout from "../components/layout"

const ContactPage = ({ data: { site: { siteMetadata: { title, siteUrl } } }, location: { pathname } }) =>
  <Layout>
    <Helmet
      title={`Contact | ${title}`}
      meta={[
        { property: 'og:title', content: `Contact | ${title}` },
        { property: 'og:url', content: `${siteUrl}${pathname}` },
      ]}
    />

    <h1 className="text-4xl font-bold leading-none mb-4">Contact</h1>
    
    <p>Feel free to reach out to me with any questions or proposals you have.</p>

    <p>
      You can contact me via email <a className="text-blue-500 font-medium" href="mailto:me@connerfritz.com?Subject=Hi&body=Hi Conner,">here</a>. 
    </p>

    <p>You can also reach me via <a href="https://twitter.com/connerfritz" className="text-blue-500 font-medium" target="_blank" rel="noreferrer">Twitter</a> and <a href="https://linkedin.com/in/connerfritz" className="text-blue-500 font-medium" target="_blank" rel="noreferrer">LinkedIn</a></p>
  </Layout>

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
  }
`;

export default ContactPage
